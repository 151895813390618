import React, { Component } from "react";
import CaseStudy from "../work/CaseStudy";
import Image1 from "../../public/assets/images/portfolio/busch/busch-1.jpg";
import Image2 from "../../public/assets/images/portfolio/busch/busch-2.jpg";
import Image3 from "../../public/assets/images/portfolio/busch/busch-3.jpg";
import Image4 from "../../public/assets/images/portfolio/busch/busch-4.jpg";
import Image5 from "../../public/assets/images/portfolio/busch/busch-5.jpg";
import Image6 from "../../public/assets/images/portfolio/busch/busch-6.jpg";

class Case extends Component{
    state = {
        client: "Anheuser Busch",
        project: "Digital Concepts",
        background: "busch",
        images: [
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
        ],
        medium: "Sketch, Graphic Design",
        role: "Concept Designer"
    }

    render(){
        const {
            client,
            project,
            background,
            about,
            images,
            medium,
            role
        } = this.state;

        return(
            <>
                <CaseStudy
                    client={client}
                    project={project}
                    background={background}
                    about={about}
                    images={images}
                    medium={medium}
                    role={role}
                />
            </>
        )
    }
}
export default Case;
