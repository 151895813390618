import React from 'react'
import Footer from "../component/footer/FooterTwo";
import Header from "../component/header/HeaderFour";
import Helmet from "../component/common/Helmet";
import PortfolioList from "../elements/portfolio/PortfolioList";

const SlideList = [
    {
        textPosition: 'text-left',
        category: "Welcome, I'm Chris Weiermiller.",
        description: "I’m a multi-dimensional artist with 15+ years of experience in Graphic Design and Illustration. I’m passionate about creating meaningful art and satisfying my clients’ vision.",
    }
]

const DesignerPortfolio = () => {
    return (
        <div>
            <Helmet pageTitle="Chris Weiermiller" />
            <Header headerPosition="header--static" logo="symbol-dark" color="color-black"/>
            <div className="slider-wrapper">
                {SlideList.map((value, index) => (
                    <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height" key={index}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="designer-thumbnail">
                                        <img src="/assets/images/about/headshot.jpg" alt="Slider Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        <h1 style={{ fontSize: "36px"}}><span>Graphic Designer</span> and <span>Illustrator</span> creating dynamic and thoughtful art in Detroit.</h1>
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            
            <div className="designer-portfolio-area ptb--120 bg_color--1">
                <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                    {/* <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" /> */}
                    <div className="row">
                        <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DesignerPortfolio
