import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";


class About extends Component{
    render(){
        let title = 'If I’m not covered in paint or ink, I’m working hard in the digital realm.',
        description1 = 'Hi, my name is Chris!  I’m based in Royal Oak, Michigan as a Graphic Designer/Illustrator who most recently spent 4+ years with the Quicken Loans family at Fathead and then Pophouse Design.  I appreciated this experience because every day presented a new challenge to work on. The variety of clients I collaborated with ranged from Mercedes-Benz to Ice Cube.  I’m a problem solver and I appreciate working directly with clients to help them bring their artistic visions to life.',
        description2 = 'Before working for Fathead and Pophouse, I spent ten years working as a freelance artist in San Francisco, California. I received a Master’s degree in Illustration from the San Francisco Academy of Art University.  After graduation, I had the opportunity to work on a variety of projects, and I used this time to diversify my experience and strengthen my skills as an artist.',
        description3 = 'Outside of work, my focus is on my family. My wife and I are lucky to have two beautiful children and every day is a great adventure.';

        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <Breadcrumb title={'About'}   />
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/full.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 style={{ lineHeight: "1.25", fontSize: "2rem", marginBottom: "2rem" }}>{title}</h2>
                                            <p className="description">{description1}</p>
                                            <p className="description">{description2}</p>
                                            <p className="description">{description3}</p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </React.Fragment>
        )
    }
}
export default About