import React, { Component } from "react";
import CaseStudy from "../work/CaseStudy";
import Image1 from "../../public/assets/images/portfolio/valparaiso/valpo-1.jpg";
import Image2 from "../../public/assets/images/portfolio/valparaiso/valpo-2.jpg";
import Image3 from "../../public/assets/images/portfolio/valparaiso/valpo-3.jpg";
import Image4 from "../../public/assets/images/portfolio/valparaiso/valpo-4.jpg";
import Image5 from "../../public/assets/images/portfolio/valparaiso/valpo-5.jpg";
import Image6 from "../../public/assets/images/portfolio/valparaiso/valpo-6.jpg";
import Image7 from "../../public/assets/images/portfolio/valparaiso/valpo-7.jpg";
import Image8 from "../../public/assets/images/portfolio/valparaiso/valpo-8.jpg";
import Image9 from "../../public/assets/images/portfolio/valparaiso/valpo-9.jpg";

class Case extends Component{
    state = {
        client: "Valparaiso",
        project: "Timeline Wall",
        background: "valpo",
        about: "This was one of the first projects where I served as Lead and Sole Designer. The process started with calls to the Athletic Director and a site visit to explore their facilities. I went back to Detroit and started by sketching out a few of the locations. I find that even when I’m doing wall graphics, it helps me visualize through sketches before diving into actual design. I also provided mood boards and visual imagery that helped the client start to carve out their vision.",
        images: [
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
            Image9,
        ],
        medium: "Sketch, Graphic Design",
        role: "Lead Designer"
    }

    render(){
        const {
            client,
            project,
            background,
            about,
            images,
            medium,
            role
        } = this.state;

        return(
            <>
                <CaseStudy
                    client={client}
                    project={project}
                    background={background}
                    about={about}
                    images={images}
                    medium={medium}
                    role={role}
                />
            </>
        )
    }
}
export default Case;
