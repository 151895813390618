import React, { Component } from "react";
import CaseStudy from "../work/CaseStudy";
import Image1 from "../../public/assets/images/portfolio/vegas/vegas-1.jpg";
import Image2 from "../../public/assets/images/portfolio/vegas/vegas-2.jpg";
import Image3 from "../../public/assets/images/portfolio/vegas/vegas-3.jpg";
import Image4 from "../../public/assets/images/portfolio/vegas/vegas-4.jpg";
import Image5 from "../../public/assets/images/portfolio/vegas/vegas-5.jpg";
import Image6 from "../../public/assets/images/portfolio/vegas/vegas-6.jpg";
import Image7 from "../../public/assets/images/portfolio/vegas/vegas-7.jpg";
import Image8 from "../../public/assets/images/portfolio/vegas/vegas-8.jpg";

class Case extends Component{
    state = {
        client: "Vegas Golden Knights",
        project: "Arena & Training Facility",
        background: "vegas",
        about: "The NHL’s Vegas team came to our team with a problem. The team was entering their first season and as exciting as that was, their concern was that there was no history or culture because they were an expansion team. The challenge for me as a designer was to create something that felt authentic and rich without feeling too overt. We went back and forth with them over what that might look like and what areas of the arena they wanted to cover with graphics. It was agreed that the graphics should be about the fans. They were going to be the key component in building a legacy and making the Knights a success. The owner was also a proud Navy Golden Knight and he supplied us with a lot of the important creeds and military sayings that became the framework for our graphics.",
        images: [
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
        ],
        medium: "Sketch, Graphic Design",
        role: "Graphic Designer"
    }

    render(){
        const {
            client,
            project,
            background,
            about,
            images,
            medium,
            role
        } = this.state;

        return(
            <>
                <CaseStudy
                    client={client}
                    project={project}
                    background={background}
                    about={about}
                    images={images}
                    medium={medium}
                    role={role}
                />
            </>
        )
    }
}
export default Case;
