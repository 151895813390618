import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";

class Portfolio extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Work' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <Breadcrumb title={'Work'}   />
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer /> 
            </React.Fragment>
        )
    }
}
export default Portfolio;