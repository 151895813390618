import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'az',
        category: 'Digital Concepts',
        title: 'Quicken Loans',
        link: "quicken-loans-arizona"
    },
    {
        image: "vegas",
        category: 'Graphic Design',
        title: 'Vegas Golden Knights',
        link: "vegas-golden-knights"
    },
    {
        image: 'valpo',
        category: 'Graphic Design',
        title: 'Valparaiso',
        link: "valparaiso"
    },
    {
        image: 'shipt',
        category: 'Digital Concepts',
        title: 'Shipt',
        link: "shipt"
    },
    {
        image: 'ohio',
        category: 'Digital Concepts',
        title: 'Ohio University',
        link: "ohio"
    },
    {
        image: 'bigthree',
        category: 'Digital Concepts',
        title: 'Big 3',
        link: "big-3"
    },
    {
        image: 'busch',
        category: 'Digital Concepts',
        title: 'Anheuser Busch',
        link: "busch"
    },
    {
        image: 'cal',
        category: 'Digital Concepts',
        title: 'University of California',
        link: "university-of-california"
    },
    {
        image: 'mes',
        category: 'Digital Concepts',
        title: 'Monster Eats Sushi',
        link: "mes"
    },
    {
        image: 'starwood',
        category: 'Digital Concepts',
        title: 'Starwood',
        link: "starwood"
    },
    {
        image: 'benz',
        category: 'Digital Concepts',
        title: 'Mercedes Benz',
        link: "mercedes-benz"
    },
    {
        image: 'princeton',
        category: 'Graphic Design',
        title: 'Princeton University',
        link: "princeton-university"
    },
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent;
        return(
            <React.Fragment> 
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <a href={`/${value.link}`}>
                            <div className={`portfolio ${styevariation}`}>
                                <div className="thumbnail-inner">
                                    <div data-black-overlay="5" className={`thumbnail ${value.image}`}></div>
                                    <div className={`${value.image}`}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{value.category}</p>
                                        <h2 className="title-white"><a href="/portfolio-details">{value.title}</a></h2>
                                        <div className="portfolio-button">
                                            <a className="rn-btn" href={`/${value.link}`}>View Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;