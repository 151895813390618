import React, { Component } from "react";
import CaseStudy from "../work/CaseStudy";
import Image0 from "../../public/assets/images/portfolio/az/az-0.jpg";
import Image1 from "../../public/assets/images/portfolio/az/az-1.jpg";
import Image2 from "../../public/assets/images/portfolio/az/az-2.jpg";
import Image3 from "../../public/assets/images/portfolio/az/az-3.jpg";
import Image4 from "../../public/assets/images/portfolio/az/az-4.jpg";
import Image5 from "../../public/assets/images/portfolio/az/az-5.jpg";
import Image6 from "../../public/assets/images/portfolio/az/az-6.jpg";
import Image7 from "../../public/assets/images/portfolio/az/az-7.jpg";
import Image8 from "../../public/assets/images/portfolio/az/az-8.jpg";
import Image9 from "../../public/assets/images/portfolio/az/az-9.jpg";
import Image10 from "../../public/assets/images/portfolio/az/az-10.jpg";
import Image11 from "../../public/assets/images/portfolio/az/az-11.jpg";
import Image12 from "../../public/assets/images/portfolio/az/az-12.jpg";
import Image13 from "../../public/assets/images/portfolio/az/az-13.jpg";
import Image14 from "../../public/assets/images/portfolio/az/az-14.jpg";
import Image15 from "../../public/assets/images/portfolio/az/az-15.jpg";
import Image16 from "../../public/assets/images/portfolio/az/az-16.jpg";
import Image17 from "../../public/assets/images/portfolio/az/az-17.jpg";
import Image18 from "../../public/assets/images/portfolio/az/az-18.jpg";
import Image19 from "../../public/assets/images/portfolio/az/az-19.jpg";

class Case extends Component{
    state = {
        client: "Quicken Loans",
        project: "Hallway",
        background: "az",
        images: [
            Image0,
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
            Image9,
            Image10,
            Image11,
            Image12,
            Image13,
            Image14,
            Image15,
            Image16,
            Image17,
            Image18,
            Image19,
        ],
        medium: "Sketch, Illustration, Graphic Design",
        role: "Lead Designer"
    }

    render(){
        const {
            client,
            project,
            background,
            about,
            images,
            medium,
            role
        } = this.state;

        return(
            <>
                <CaseStudy
                    client={client}
                    project={project}
                    background={background}
                    about={about}
                    images={images}
                    medium={medium}
                    role={role}
                />
            </>
        )
    }
}
export default Case;
