import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import Home from './home/Home';
import About from "./elements/About";
import Contact from "./elements/Contact";
import Portfolio from "./elements/Portfolio";

import error404 from "./elements/error404";

// Work
import VegasGoldenKnights from "./work/VegasGoldenKnights";
import Valparaiso from "./work/Valparaiso";
import Shipt from "./work/Shipt";
import Ohio from "./work/Ohio";
import QLArizona from "./work/QLArizona";
import MES from "./work/MES";
import Busch from "./work/Busch";
import BigThree from "./work/BigThree";
import Cal from "./work/Cal";
import Starwood from "./work/Starwood";
import Benz from "./work/Benz";
import Princeton from "./work/Princeton";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/work`} component={Portfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    
                    {/* Work */}
                    <Route exact path={`${process.env.PUBLIC_URL}/vegas-golden-knights`} component={VegasGoldenKnights}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/valparaiso`} component={Valparaiso}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/shipt`} component={Shipt}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/ohio`} component={Ohio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/quicken-loans-arizona`} component={QLArizona}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/mes`} component={MES}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/big-3`} component={BigThree}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/university-of-california`} component={Cal}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/busch`} component={Busch}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/starwood`} component={Starwood}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/mercedes-benz`} component={Benz}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/princeton-university`} component={Princeton}/>
                    
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();