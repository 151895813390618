import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ScrollToTop from 'react-scroll-up';
// import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";

class CaseStudy extends Component{
    state = {
        client: this.props.client,
        project: this.props.project,
        background: this.props.background,
        about: this.props.about || null,
        images: this.props.images,
        medium: this.props.medium,
        role: this.props.role
    }

    render(){
        const {
            client,
            project,
            background,
            about,
            images,
            medium,
            role
        } = this.state;

        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                <div
                    className={`rn-page-title-area pt--120 pb--190 bg_image ${background}`}
                    data-black-overlay="7"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title title-white">
                                        {client}
                                    </h2>
                                    <p>
                                        {project}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner bottom-space">
                                        <h2>{project}</h2>
                                        <p className="subtitle">
                                            {about ? about : null}
                                        </p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Client</span>
                                                <h4>{client}</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Medium</span>
                                                <h4>{medium}</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Role</span>
                                                <h4>{role}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        {
                                            images.map((image) => {
                                                return(
                                                    <div className="thumb mb--30">
                                                        <img src={image} alt={`${client} ${project} portfolio`}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-btn pb--80" style={{ display: "flex", justifyContent: "center" }}>
                    <a className="rn-button-style--2 btn-solid" href="/work">
                        <span>More Work</span>
                    </a>
                </div>
                <Footer />  
            </React.Fragment>
        )
    }
}
export default CaseStudy;
