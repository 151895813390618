import React, { Component } from "react";
import CaseStudy from "../work/CaseStudy";
import Image1 from "../../public/assets/images/portfolio/benz/benz-1.jpg";
import Image2 from "../../public/assets/images/portfolio/benz/benz-2.jpg";
import Image3 from "../../public/assets/images/portfolio/benz/benz-3.jpg";
import Image4 from "../../public/assets/images/portfolio/benz/benz-4.jpg";
import Image5 from "../../public/assets/images/portfolio/benz/benz-5.jpg";
import Image6 from "../../public/assets/images/portfolio/benz/benz-6.jpg";
import Image7 from "../../public/assets/images/portfolio/benz/benz-7.jpg";
import Image8 from "../../public/assets/images/portfolio/benz/benz-8.jpg";
import Image9 from "../../public/assets/images/portfolio/benz/benz-9.jpg";
// import Image10 from "../../public/assets/images/portfolio/benz/benz-10.jpg";
import Image11 from "../../public/assets/images/portfolio/benz/benz-11.jpg";
import Image12 from "../../public/assets/images/portfolio/benz/benz-12.jpg";
import Image13 from "../../public/assets/images/portfolio/benz/benz-13.jpg";
import Image14 from "../../public/assets/images/portfolio/benz/benz-14.jpg";
import Image15 from "../../public/assets/images/portfolio/benz/benz-15.jpg";
import Image16 from "../../public/assets/images/portfolio/benz/benz-16.jpg";
import Image17 from "../../public/assets/images/portfolio/benz/benz-17.jpg";
import Image18 from "../../public/assets/images/portfolio/benz/benz-18.jpg";

class Case extends Component{
    state = {
        client: "Mercedes Benz",
        project: "Dealership",
        background: "benz",
        images: [
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
            Image9,
            Image11,
            Image12,
            Image13,
            Image14,
            Image15,
            Image16,
            Image17,
            Image18,
        ],
        medium: "Sketch, Graphic Design",
        role: "Graphic Designer"
    }

    render(){
        const {
            client,
            project,
            background,
            about,
            images,
            medium,
            role
        } = this.state;

        return(
            <>
                <CaseStudy
                    client={client}
                    project={project}
                    background={background}
                    about={about}
                    images={images}
                    medium={medium}
                    role={role}
                />
            </>
        )
    }
}
export default Case;
