import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Chris Weiermiller | Design Portfolio</title>
                    <meta name="description" content="The design portfolio of Chris Weiermiller, a designer based in Detroit, MI." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
